<template>
  <section>
    <navi-bar>
      {{$t('front.common.depositApply')}}
    </navi-bar>
    <template v-if="type == 'apply'">
      <div class="moneyread">
        <h3>{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>결제양식에 따라 결제하시면 입금처리가 완료 됩니다.</span></li>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>입금 전 입금계좌정보를 꼭 확인해주세요.</span></li>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>입금관련 문제 발생시 고객센터로 문의 주시기 바랍니다.</span></li>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>충전요청 금액을 선택하거나, 직접 입력하여 충전할 수 있습니다. 입금자명과 계좌정보가 일치 하는지 확인해 주세요.</span></li>
        </ul>
      </div>
      <ul class="appliinput">
        <li>예금주</li>
        <li>{{coinInfo.accountname}}</li>
      </ul>
      <ul class="appliinput">
        <li>은행명</li>
        <li>{{coinInfo.bank}}</li>
      </ul>
      <ul class="appliinput">
        <li>계좌번호</li>
        <li>{{coinInfo.accountnumber}}</li>
      </ul>
      <div class="appliwrite mt30">
        <p class="input"><input type="text" pattern="[0-9.,]+" class="numb w110" placeholder="입금자명" v-model="coinInfo.accountname"/></p>
      </div>
      <p class="check"><img src="@/assets_mobile/img/icon_gameMoney.svg" />입금자 이름이 다르면 입금확인을 할 수 없으니 주의하시기 바랍니다.</p>
      <ul class="applihave mt30">
        <li><img src="@/assets_mobile/img/icon_moneys.svg" /></li>
        <li>{{$t('front.cash.moneyHave')}}</li>
        <li> : <span>{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
      </ul>
      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{$t('front.board.apply')}}</a>
      </div>
      <div class="applylistbtn mb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.cash.depositCheck')}} ></a>
      </div>
    </template>
    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />
      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} ({{dateFormat(item.regDate)}})</li>
              <li class="ft15 mt10">{{$t('front.board.processMoney')}}</li>
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="wh fb">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>
              <li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"> &lt; {{$t('front.common.depositApply')}}</a>
      </div>
    </template>
  </section>
</template>
<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Charge from '@/views/member/money/coinCharge'
export default {
  name: 'charge',
  data () {
    return {
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Charge
  ]
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>

<style scoped>
.appliinput {padding: 0 25px;color: #fff;}
.appliinput li {border-color: #333;}
.check {font-size: 11px;display: flex;align-items: center;gap: 5px;padding: 10px 25px 0;color: #fff;}
.morebtn {margin-bottom: 10px !important;}
</style>
